import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { DefaultComponent } from './default.component';
import { FragmentArticulos } from 'src/app/modules/fragmentArticulos/fragmentArticulos.component';
import { LoginComponent } from 'src/app/modules/../components/auth/login/login.component';
import { LogininicioComponent } from 'src/app/modules/../components/auth/loginincio/logininicio.component';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
//* Migracion componetes a fragment
import { FragmentIndicadores } from 'src/app/modules/fragmentIndicadores/fragmentIndicadores.component';
import { FragmentIndicadoresmes } from 'src/app/modules/fragmentIndicadoresmes/fragmentIndicadoresmes.component';
import { FragmentIndicadoresRuta } from 'src/app/modules/fragmentIndicadoresRuta/fragmentIndicadoresRuta.component';
import { FragmentReporte } from 'src/app/modules/fragmentReportes/fragmentReporte.component';
import { FragmentTareas } from 'src/app/modules/fragmentTareas/fragmentTareas.component';
import { FragmentGeoTareas } from 'src/app/modules/fragmentGeoTareas/fragmentGeoTareas.component';
import { FragmentClientes } from 'src/app/modules/fragmentClientes/fragmentClientes.component';
import { FragmentContactos } from 'src/app/modules/fragmentContactos/fragmentContactos.component';
import { FragmentPrecios } from 'src/app/modules/fragmentPrecios/fragmentPrecios.component';
import { FragmentRutero} from 'src/app/modules/fragmentRutero/fragmentRutero.component';
import { FragmentBarrios} from 'src/app/modules/fragmentBarrios/fragmentBarrios.component';
import { FragmentPrefijos} from 'src/app/modules/fragmentPrefijos/fragmentPrefijos.component';
import { FragmenClientesAprobacion} from 'src/app/modules/fragmentClienteAprobacion/fragmenClientesAprobacion.component';
import { FragmenClientesActulizacion} from 'src/app/modules/fragmentClienteActulizacion/fragmenClientesActulizacion.component';
import { FragmentAprobar} from 'src/app/modules/fragmentAprobar/fragmentAprobar.component';
import { FragmentActulizacion} from 'src/app/modules/fragmentActulizacion/fragmentActulizacion.component';
import { FragmenPedidos} from 'src/app/modules/fragmentPedidos/fragmentPedidos.component';
import { FragmentDetalle} from 'src/app/modules/fragmentDetalle/fragmentDetalle.component';
import { FragmentIndicadoresPedidos} from 'src/app/modules/fragmentIndicadorespedidos/fragmentIndicadorespedidos.component';
import { FragmentIndicadoresRuteros} from 'src/app/modules/fragmentIndicadoresRuteros/fragmentIndicadoresRuteros.component';
import { FragmentActivaciones} from 'src/app/modules/fragmentActivaciones/fragmentActivaciones.component';
import { FragmentAsesores} from 'src/app/modules/fragmentGeoAsesores/fragmentAsesores.component';
import { FragmentReportePreventa} from 'src/app/modules/fragmentReportesPreventa/fragmentReportePreventa.component';
import { FragmentNavegador} from 'src/app/modules/fragmentNavegador/fragmentNavegador.component';
import { FragmentAdministracion} from 'src/app/modules/fragmentAdministracion/fragmentAdministracion.component';
import { FragmentFotoTarea} from 'src/app/modules/fragmentFotoTarea/fragmentFotoTarea.component';
import { FragmentCargarTareas} from 'src/app/modules/slcweb/fragmentCargarTareas/fragmentCargarTareas.component';
import { FragmentDetalleOperacion} from 'src/app/modules/slcweb/fragmentDetalleOperacion/fragmentDetalleOperacion.component';
import { FragmentOrdenTransportes} from 'src/app/modules/slcweb/ordenTransporte/fragmentOrdenTransportes/fragmentOrdenTransportes.component';
import { fragmentMapsOT} from 'src/app/modules/slcweb/fragmentMapsOT/fragmentMapsOT.component';
import { FragmentBancos} from 'src/app/modules/slcweb/fragmentBancos/fragmentBancos.component';
import { DialogEditarBanco} from 'src/app/modules/slcweb/dialogEditarBanco/dialogEditarBanco.component';
import { DialogAgregarBanco} from 'src/app/modules/slcweb/dialogAgregarBanco/dialogAgregarBanco.component';
import { DialogEliminarBanco} from 'src/app/modules/slcweb/dialogEliminarBanco/dialogEliminarBanco.component';
import { FragmentAuditoriaBancos} from 'src/app/modules/slcweb/fragmentAuditoriaBancos/fragmentAuditoriaBancos.component';
import { FragmentTipoDoc} from 'src/app/modules/slcweb/tipoDoc/fragmentTipoDoc/fragmentTipoDoc.component';
import { DialogAgregarTipoDoc} from 'src/app/modules/slcweb/tipoDoc/dialogAgregarTipoDoc/dialogAgregarTipoDoc.component';
import { DialogEliminarTipoDoc} from 'src/app/modules/slcweb/tipoDoc/dialogEliminarTipoDoc/dialogEliminarTipoDoc.component';
import { DialogEditarTipoDoc} from 'src/app/modules/slcweb/tipoDoc/dialogEditarTipoDoc/dialogEditarTipoDoc.component';
import { FragmentAuditoriaTipoDoc} from 'src/app/modules/slcweb/tipoDoc/fragmentAuditoriaTipoDoc/fragmentAuditoriaTipoDoc.component';
import { FragmentTipoTel} from 'src/app/modules/slcweb/tipoTel/fragmentTipoTel/fragmentTipoTel.component';
import { DialogAgregarTipoTel} from 'src/app/modules/slcweb/tipoTel/dialogAgregarTipoTel/dialogAgregarTipoTel.component';
import { DialogEliminarTipoTel} from 'src/app/modules/slcweb/tipoTel/dialogEliminarTipoTel/dialogEliminarTipoTel.component';
import { DialogEditarTipoTel} from 'src/app/modules/slcweb/tipoTel/dialogEditarTipoTel/dialogEditarTipoTel.component';
import { FragmentAuditoriaTipoTel} from 'src/app/modules/slcweb/tipoTel/fragmentAuditoriaTipoTel/fragmentAuditoriaTipoTel.component';
import { FragmentTipoOperacion} from 'src/app/modules/slcweb/tipoOpereacion/fragmentTipoOperacion/fragmentTipoOperacion.component';
import { DialogAgregarTipoOP} from 'src/app/modules/slcweb/tipoOpereacion/dialogAgregarTipoOp/dialogAgregarTipoOp.component';
import { DialogEliminarTipoOp} from 'src/app/modules/slcweb/tipoOpereacion/dialogEliminarTipoOp/dialogEliminarTipoOp.component';
import { DialogEditarTipoOp} from 'src/app/modules/slcweb/tipoOpereacion/dialogEditarTipoOp/dialogEditarTipoOp.component';
import { FragmentAuditoriaTipoOp} from 'src/app/modules/slcweb/tipoOpereacion/fragmentAuditoriaTipoOp/fragmentAuditoriaTipoOp.component';
import { FragmentTipoVehiculo} from 'src/app/modules/slcweb/tipoveniculos/fragmentTipoVehiculo/fragmentTipoVehiculo.component';
import { DialogAgregarTipoVehiculo} from 'src/app/modules/slcweb/tipoveniculos/dialogAgregarTipoVehiculo/dialogAgregarTipoVehiculo.component';
import { DialogEliminarTipoVehiculo} from 'src/app/modules/slcweb/tipoveniculos/dialogEliminarTipoVehiculo/dialogEliminarTipoVehiculo.component';
import { DialogEditarTipoVehiculo} from 'src/app/modules/slcweb/tipoveniculos/dialogEditarTipoVehiculo/dialogEditarTipoVehiculo.component';
import { FragmentAuditoriaTipoVehiculo} from 'src/app/modules/slcweb/tipoveniculos/fragmentAuditoriaTipoVehiculo/fragmentAuditoriaTipoVehiculo.component';
import { FragmentMarcaVehiculo} from 'src/app/modules/slcweb/marcavehiculo/fragmentMarcaVehiculo/fragmentMarcaVehiculo.component';
import { DialogAgregarMarca} from 'src/app/modules/slcweb/marcavehiculo/dialogAgregarMarca/dialogAgregarMarca.component';
import { DialogEliminarMarca} from 'src/app/modules/slcweb/marcavehiculo/dialogEliminarMarca/dialogEliminarMarca.component';
import { DialogEditarMarca} from 'src/app/modules/slcweb/marcavehiculo/dialogEditarMarca/dialogEditarMarca.component';
import { FragmentAuditoriaMarca} from 'src/app/modules/slcweb/marcavehiculo/fragmentAuditoriaMarca/fragmentAuditoriaMarca.component';
import { FragmentVehiculo} from 'src/app/modules/slcweb/vehiculo/fragmentVehiculo/fragmentVehiculo.component';
import { DialogAgregarVehiculo} from 'src/app/modules/slcweb/vehiculo/dialogAgregarVehiculo/dialogAgregarVehiculo.component';
import { FragmentConfiVh} from 'src/app/modules/slcweb/configuracionVehiculo/fragmentConfiVh/fragmentConfiVh.component';
import { DialogAgregarConfiVh} from 'src/app/modules/slcweb/configuracionVehiculo/dialogAgregarConfiVh/dialogAgregarConfiVh.component';
import { DialogEliminarConfiVh} from 'src/app/modules/slcweb/configuracionVehiculo/dialogEliminarConfiVh/dialogEliminarConfiVh.component';
import { DialogEditarConfiVh} from 'src/app/modules/slcweb/configuracionVehiculo/dialogEditarConfiVh/dialogEditarConfiVh.component';
import { FragmentAuditoriaConfiVh} from 'src/app/modules/slcweb/configuracionVehiculo/fragmentAuditoriaConfiVh/fragmentAuditoriaConfiVh.component';
import { DialogEditarVehiculo} from 'src/app/modules/slcweb/vehiculo/dialogEditarVehiculo/dialogEditarVehiculo.component';
import { FragmentAuditoriaVehiculo} from 'src/app/modules/slcweb/vehiculo/fragmentAuditoriaVehiculo/fragmentAuditoriaVehiculo.component';
import { FragmentTercero} from 'src/app/modules/slcweb/tercero/fragmentTercero/fragmentTercero.component';
import { DialogAgregarTercero} from 'src/app/modules/slcweb/tercero/dialogAgregarTercero/dialogAgregarTercero.component';
import { FragmentDepartamentos} from 'src/app/modules/slcweb/localizacion/deparmentos/fragmentDepartamentos/fragmentDepartamentos.component';
import { FragmentTerceroSeguridadVial} from 'src/app/modules/slcweb/tercero/fragmentTerceroSeguridadVial/fragmentTerceroSeguridadVial.component';


import { SharedModule } from 'src/app/shared/shared.module';
import { MatSidenavModule, MatFormFieldModule,MatInputModule,MatDividerModule, MatCardModule, MatPaginatorModule, MatTableModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DashboardService } from 'src/app/modules/dashboard.service';

import { HttpModule, JsonpModule } from '@angular/http';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { UploaderModule } from '@syncfusion/ej2-angular-inputs';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { CheckBoxModule  } from '@syncfusion/ej2-angular-buttons';
import { GridAllModule } from '@syncfusion/ej2-angular-grids';
import { FragmentRutas } from 'src/app/modules/fragmentRutas/fragmentRutas.component';
import { FragmentIndicadoresapi} from 'src/app/modules/fragmentIndicadoresapi/fragmentIndicadoresapi.component';
import {MatDialogModule} from "@angular/material";



import {A11yModule} from '@angular/cdk/a11y';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';

import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';


import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';

import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';

import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';

import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';

import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import {OverlayModule} from '@angular/cdk/overlay';
import { AgmCoreModule } from '@agm/core';
import { DialogAgregarDepartamentos } from 'src/app/modules/slcweb/localizacion/deparmentos/dialogAgregarDepartamentos/dialogAgregarDepartamentos.component';
import { DialogEliminarDepartamento } from 'src/app/modules/slcweb/localizacion/deparmentos/dialogEliminarDepartamento/dialogEliminarDepartamento.component';
import { DialogEditarDepartamento } from 'src/app/modules/slcweb/localizacion/deparmentos/dialogEditarDepartamento/dialogEditarDepartamento.component';
import { FragmentAuditoriaDepartamento } from 'src/app/modules/slcweb/localizacion/deparmentos/fragmentAuditoriaDepartamento/fragmentAuditoriaDepartamento.component';
import { FragmentCiudades } from 'src/app/modules/slcweb/localizacion/ciudad/fragmentCiudades/fragmentCiudades.component';
import { DialogAgregarCiudades } from 'src/app/modules/slcweb/localizacion/ciudad/dialogAgregarCiudades/dialogAgregarCiudades.component';
import { DialogEliminarCiudades } from 'src/app/modules/slcweb/localizacion/ciudad/dialogEliminarCiudades/dialogEliminarCiudades.component';
import { DialogEditarCiudad } from 'src/app/modules/slcweb/localizacion/ciudad/dialogEditarCiudad/dialogEditarCiudad.component';
import { FragmentAuditoriaCiudad } from 'src/app/modules/slcweb/localizacion/ciudad/fragmentAuditoriaCiudad/fragmentAuditoriaCiudad.component';
import { FragmentBarrio } from 'src/app/modules/slcweb/localizacion/barrios/fragmentBarrio/fragmentBarrio.component';
import { DialogAgregarBarrio } from 'src/app/modules/slcweb/localizacion/barrios/dialogAgregarBarrio/dialogAgregarBarrio.component';
import { DialogEliminarBarrio } from 'src/app/modules/slcweb/localizacion/barrios/dialogEliminarBarrio/dialogEliminarBarrio.component';
import { DialogEditarBarrio } from 'src/app/modules/slcweb/localizacion/barrios/dialogEditarBarrio/dialogEditarBarrio.component';
import { FragmentAuditoriaBarrio } from 'src/app/modules/slcweb/localizacion/barrios/fragmentAuditoriaBarrio/fragmentAuditoriaBarrio.component';
import { DialogEditarTercero } from 'src/app/modules/slcweb/tercero/dialogEditarTercero/dialogEditarTercero.component';
import { FragmentAuditoriaTerceros } from 'src/app/modules/slcweb/tercero/fragmentAuditoriaTerceros/fragmentAuditoriaTerceros.component';
import { FragmentTransportador } from 'src/app/modules/slcweb/transportador/fragmentTransportador/fragmentTransportador.component';
import {  DialogAgregarTransportadores } from 'src/app/modules/slcweb/transportador/dialogAgregarTransportadores/dialogAgregarTransportadores.component';
import {  DialogAgregarVehiculoTrans } from 'src/app/modules/slcweb/transportador/dialogAgregarVehiculoTrans/dialogAgregarVehiculoTrans.component';
import { FragmentTransporte } from 'src/app/modules/slcweb/transportador/fragmentTransporte/fragmentTransporte.component';
import { DialogAgregarPropietario } from 'src/app/modules/slcweb/vehiculo/dialogAgregarPropietario/dialogAgregarPropietario.component';
import { DialogAgregarTerceroVh } from 'src/app/modules/slcweb/vehiculo/dialogAgregarTerceroVh/dialogAgregarTerceroVh.component';
import { dialogAgregarPropietarioVehiculo } from 'src/app/modules/slcweb/transportador/dialogAgregarPropietarioVehiculo/dialogAgregarPropietarioVehiculo.component';
import { DialogAgregarConductor } from 'src/app/modules/slcweb/transportador/dialogAgregarConductor/dialogAgregarConductor.component';
import { DialogAgregarTerceroC } from 'src/app/modules/slcweb/transportador/dialogAgregarTerceroC/dialogAgregarTerceroC.component';
import { DialogAgregarDocumentos } from 'src/app/modules/slcweb/transportador/dialogAgregarDocumentos/dialogAgregarDocumentos.component';
import { DialogAgregarTenedor } from 'src/app/modules/slcweb/vehiculo/dialogAgregarTenedor/dialogAgregarTenedor.component';
import { DialogAgregarTerceroTenedor } from 'src/app/modules/slcweb/vehiculo/dialogAgregarTerceroTenedor/dialogAgregarTerceroTenedor.component';
import { DialogAgregarTerceroLicencia } from 'src/app/modules/slcweb/transportador/dialogAgregarTerceroLicencia/dialogAgregarTerceroLicencia.component';
import { DialogAgregarOrdenTransporte } from 'src/app/modules/slcweb/ordenTransporte/dialogAgregarOrdenTransporte/dialogAgregarOrdenTransporte.component';
import { DialogAgregarArchivo } from 'src/app/modules/slcweb/ordenTransporte/dialogAgregarArchivo/dialogAgregarArchivo.component';
import { DialogAgregarDetalleOrden } from 'src/app/modules/slcweb/ordenTransporte/dialogAgregarDetalleOrden/dialogAgregarDetalleOrden.component';
import { DialogAgregarArchivoDetalle } from 'src/app/modules/slcweb/ordenTransporte/dialogAgregarArchivoDetalle/dialogAgregarArchivoDetalle.component';
import { FragmentPlanilla } from 'src/app/modules/slcweb/planilla/fragmentPlanilla/fragmentPlanilla.component';
import { FragmentPlanillaDetalle } from 'src/app/modules/slcweb/planilla/fragmentPlanillaDetalle/fragmentPlanillaDetalle.component';
import { DialogAprobarPlanilla } from 'src/app/modules/slcweb/planilla/dialogAprobarPlanilla/dialogAprobarPlanilla.component';
import { FragmentPlanillaRecaudo } from 'src/app/modules/slcweb/planilla/fragmentPlanillaRecaudo/fragmentPlanillaRecaudo.component';
import { DialogAprobarConsignacion } from 'src/app/modules/slcweb/planilla/dialogAprobarConsignacion/dialogAprobarConsignacion.component';
import { FragmentMaestroArticulos } from 'src/app/modules/slcweb/maestros/fragmentMaestroArticulos/fragmentMaestroArticulos.component';
import { DialogAgregarArticulo } from 'src/app/modules/slcweb/maestros/dialogAgregarArticulo/dialogAgregarArticulo.component';
import { FragmentMaestroClientes } from 'src/app/modules/slcweb/maestros/fragmentMaestroClientes/fragmentMaestroClientes.component';
import { FragmentIndicadoresVrt } from 'src/app/modules/slcweb/fragmentIndicadoresVrt/fragmentIndicadoresVrt.component';
import { DialogPuntosMpas } from 'src/app/modules/slcweb/fragmentMapsOT/dialogPuntosMpas/dialogPuntosMpas.component';
import { DialogNovedades } from 'src/app/modules/slcweb/fragmentIndicadoresVrt/dialogNovedades/dialogNovedades.component';
import { DialogPendientes } from 'src/app/modules/slcweb/fragmentIndicadoresVrt/dialogPendientes/dialogPendientes.component';
import { DialogExitosas } from 'src/app/modules/slcweb/fragmentIndicadoresVrt/dialogExitosas/dialogExitosas.component';
import { FragmentMpasTransporte } from 'src/app/modules/slcweb/fragmentMpasTransporte/fragmentMpasTransporte.component';
import { FragmentIndicadoresapiTransporte } from 'src/app/modules/fragmentIndicadoresapiTransporte/fragmentIndicadoresapiTransporte.component';
import { DialogAgregarArchivoGeo } from 'src/app/modules/slcweb/ordenTransporte/dialogAgregarArchivoGeo/dialogAgregarArchivoGeo.component';
import { DialogAgregarOrdenTransporteGeo } from 'src/app/modules/slcweb/ordenTransporte/dialogAgregarOrdenTransporteGeo/dialogAgregarOrdenTransporteGeo.component';
import { FragmentEncuestas } from 'src/app/modules/slcweb/ordenTransporte/fragmentEncuestas/fragmentEncuestas.component';
import { FragmentDetalleEncuesta } from 'src/app/modules/slcweb/planilla/fragmentDetalleEncuesta/fragmentDetalleEncuesta.component';
import { DialogAgregarEncuesta} from 'src/app/modules/slcweb/tercero/dialogAgregarEncuesta/dialogAgregarEncuesta.component';
import { DialogAgregarEncuestaDetalle } from 'src/app/modules/slcweb/tercero/dialogAgregarEncuestaDetalle/dialogAgregarEncuestaDetalle.component';
import { DialogAgregarEncuestaFecha } from 'src/app/modules/slcweb/tercero/dialogAgregarEncuestaFecha/dialogAgregarEncuestaFecha.component';
import { DialogAgregarEncuestaSeleccion } from 'src/app/modules/slcweb/tercero/dialogAgregarEncuestaSeleccion/dialogAgregarEncuestaSeleccion.component';
import { FragmentIndicadoContent } from 'src/app/modules/fragmentIndicadoContent/fragmentIndicadoContent.component';
import { FragmentIndicadoresCumplimiento } from 'src/app/modules/fragmentIndicadoresCumplimiento/fragmentIndicadoresCumplimiento.component';
import { FragmentIndicadoresGeo } from 'src/app/modules/fragmentIndicadoresGeo/fragmentIndicadoresGeo.component';
import { FragmentIndicadoresapicosto } from 'src/app/modules/fragmentIndicadoresapicosto/fragmentIndicadoresapicosto.component';
import { FragmentIndicadoresapidevolucion } from 'src/app/modules/fragmentIndicadoresapidevolucion/fragmentIndicadoresapidevolucion.component';
import { FragmentTerceroCreacionEncuestas } from 'src/app/modules/slcweb/tercero/fragmentTerceroCreacionEncuestas/fragmentTerceroCreacionEncuestas.component';
import { DialogEditarEncuestaDetalle } from 'src/app/modules/slcweb/tercero/dialogEditarEncuestaDetalle/dialogEditarEncuestaDetalle.component';
import { DialogEditarEncuestaSeleccion } from 'src/app/modules/slcweb/tercero/dialogEditarEncuestaSeleccion/dialogEditarEncuestaSeleccion.component';
import { DialogEditarEncuestaFecha } from 'src/app/modules/slcweb/tercero/dialogEditarEncuestaFecha/dialogEditarEncuestaFecha.component';
import { FragmentEncuestasParqueadero } from 'src/app/modules/slcweb/ordenTransporte/fragmentEncuestasParqueadero/fragmentEncuestasParqueadero.component';
import { FragmentDetalleVehiculo } from 'src/app/modules/slcweb/planilla/fragmentDetalleVehiculo/fragmentDetalleVehiculo.component';
import { DialogEliminarEncuesta } from 'src/app/modules/slcweb/tipoDoc/dialogEliminarEncuesta/dialogEliminarEncuesta.component';
import { DialogEliminarEncuestaDetalle } from 'src/app/modules/slcweb/tipoDoc/dialogEliminarEncuestaDetalle/dialogEliminarEncuestaDetalle.component';

@NgModule({
  declarations: [
    DefaultComponent,
    FragmentIndicadores,
    FragmentTareas,
    FragmentReporte,
    FragmentRutas,
    FragmentAdministracion,
    LoginComponent,
    LogininicioComponent,
    FragmentGeoTareas,
    FragmentIndicadoresRuta,
    FragmentIndicadoresapi,
    FragmentActivaciones,
    FragmentArticulos,
    FragmentClientes,
    FragmentContactos,
    FragmentPrecios,
    FragmentRutero,
    FragmentBarrios,
    FragmentPrefijos,
    FragmentEncuestas,
    FragmenClientesAprobacion,
    FragmentAprobar,
    FragmenPedidos,
    FragmentDetalle,
    FragmentDetalleVehiculo,
    FragmentAsesores,
    FragmentEncuestasParqueadero,
    FragmentIndicadoresPedidos,
    FragmentIndicadoresmes,
    FragmentIndicadoresRuteros,
    FragmenClientesActulizacion,
    FragmentIndicadoresapidevolucion,
    FragmentActulizacion,
    FragmentReportePreventa,
    FragmentDetalleEncuesta,
    FragmentNavegador,
    FragmentFotoTarea,
    FragmentIndicadoresapicosto,
    FragmentIndicadoresGeo,
    FragmentTerceroSeguridadVial,
    FragmentCargarTareas,
    FragmentDetalleOperacion,
    FragmentOrdenTransportes,
    fragmentMapsOT,
    FragmentBancos,
    FragmentIndicadoContent,
    FragmentAuditoriaBancos,
    DialogEditarBanco,
    DialogAgregarBanco,
    DialogEliminarBanco,
    DialogAgregarTipoDoc,
    DialogEliminarEncuesta,
    FragmentTipoDoc,
    DialogEliminarTipoDoc,
    DialogEditarTipoDoc,
    FragmentAuditoriaTipoDoc,
    FragmentTipoTel,
    DialogAgregarTipoTel,
    DialogEliminarTipoTel,
    DialogEditarTipoTel,
    FragmentAuditoriaTipoTel,
    FragmentTipoOperacion,
    FragmentIndicadoresCumplimiento,
    DialogAgregarTipoOP,
    DialogEliminarTipoOp,
    DialogEditarTipoOp,
    FragmentAuditoriaTipoOp,
    FragmentTipoVehiculo,
    DialogAgregarTipoVehiculo,
    DialogEliminarTipoVehiculo,
    DialogEditarTipoVehiculo,
    FragmentAuditoriaTipoVehiculo,
    FragmentMarcaVehiculo,
    DialogAgregarMarca,
    DialogEliminarMarca,
    DialogEditarMarca,
    FragmentAuditoriaMarca,
    FragmentVehiculo,
    DialogAgregarVehiculo,
    FragmentConfiVh,
    DialogAgregarConfiVh,
    DialogEliminarConfiVh,
    DialogAgregarEncuestaFecha,
    DialogEditarConfiVh,
    DialogEliminarEncuestaDetalle,
    DialogEditarEncuestaFecha,
    FragmentAuditoriaConfiVh,
    DialogEditarVehiculo,
    DialogEditarEncuestaDetalle,
    DialogAgregarEncuesta,
    DialogEditarEncuestaSeleccion,
    DialogAgregarEncuestaDetalle,
    FragmentAuditoriaVehiculo,
    FragmentTercero,
    DialogAgregarTercero,
    FragmentDepartamentos,
    DialogAgregarDepartamentos,
    DialogEliminarDepartamento,
    DialogEditarDepartamento,
    FragmentAuditoriaDepartamento,
    FragmentTerceroCreacionEncuestas,
    FragmentCiudades,
    DialogAgregarCiudades,
    DialogEliminarCiudades,
    DialogEditarCiudad,
    FragmentAuditoriaCiudad,
    FragmentBarrio,
    DialogAgregarBarrio,
    DialogEliminarBarrio,
    DialogEditarBarrio,
    FragmentAuditoriaBarrio,
    DialogEditarTercero,
    FragmentAuditoriaTerceros,
    FragmentTransportador,
    DialogAgregarTransportadores,
    DialogAgregarVehiculoTrans,
    FragmentTransporte,
    DialogAgregarPropietario,
    DialogAgregarTerceroVh,
    dialogAgregarPropietarioVehiculo,
    DialogAgregarConductor,
    DialogAgregarTerceroC,
    DialogAgregarDocumentos,
    DialogAgregarTenedor,
    DialogAgregarTerceroTenedor,
    DialogAgregarTerceroLicencia,
    DialogAgregarOrdenTransporte,
    DialogAgregarArchivo,
    DialogAgregarDetalleOrden,
    DialogAgregarArchivoDetalle,
    DialogPuntosMpas,
    FragmentPlanilla,
    FragmentPlanillaDetalle, 
    DialogAprobarPlanilla,
    FragmentPlanillaRecaudo,
    DialogAprobarConsignacion,
    FragmentMaestroArticulos,
    DialogAgregarArticulo,
    FragmentMaestroClientes,
    FragmentIndicadoresVrt,
    DialogNovedades,
    DialogPendientes,
    DialogExitosas,
    DialogAgregarEncuestaSeleccion,
    FragmentMpasTransporte,
    FragmentIndicadoresapiTransporte,
    DialogAgregarArchivoGeo,
    DialogAgregarOrdenTransporteGeo
    

  ],
  exports: [
    A11yModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    OverlayModule,
    PortalModule,
    ScrollingModule,
    MatButtonModule,
    
  
  ],
  imports: [
  
    CommonModule,
    RouterModule,

    SharedModule,
    MatSidenavModule,
    MatDividerModule,
    MatFormFieldModule,
    FlexLayoutModule,
    MatCardModule,
    MatPaginatorModule,
    MatTableModule,
    MatInputModule,
    A11yModule,
   
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,  
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    OverlayModule,
    PortalModule,
    ScrollingModule,
    AgmCoreModule,
    MatButtonModule,
    SharedModule, CheckBoxModule,GridAllModule, UploaderModule, DialogModule, FormsModule, CommonModule, ReactiveFormsModule, HttpModule, JsonpModule, BrowserModule

  ],
  providers: [
    DashboardService
  ]
  
})
export class DefaultModule { }
