
import { ConfiguracionesService } from '../../../../shared/configuraciones.service';
import { Component, OnInit, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MatDialog, MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { formatDate } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { DialogAgregarTercero } from '../dialogAgregarTercero/dialogAgregarTercero.component';
import { Router } from '@angular/router';
import { DialogAgregarEncuesta } from '../dialogAgregarEncuesta/dialogAgregarEncuesta.component';
import { DialogAgregarEncuestaFecha } from '../dialogAgregarEncuestaFecha/dialogAgregarEncuestaFecha.component';
import { DialogAgregarEncuestaSeleccion } from '../dialogAgregarEncuestaSeleccion/dialogAgregarEncuestaSeleccion.component';

export interface DialogDataVh {
  id: string;
  tipoOrigen: string;
  nombres: string;
  apellidos: string;
  tipoDoc: string;
  identificacion: string;
  estado: string;
  idTp: string;
}

@Component({
  selector: 'app-DialogAgregarEncuestaDetalle',
  templateUrl: './DialogAgregarEncuestaDetalle.component.html',
  styleUrls: ['./DialogAgregarEncuestaDetalle.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class DialogAgregarEncuestaDetalle implements OnInit {
  id: string;
  nombres: string;
  apellidos: string;
  tipoDoc: string;
  identificacion: string;
  estado: string;
  idTp: string;
  public marcas;
  public marcafm;
  public confifm;
  public confisvh;
  public estados;
  public soatvenfm;
  public tecnovenfm;
  public modelos;
  public clases;
  public modelofm;
  public imgEstadofm;
  public estadofm;
  public clasefm;
  public codigo;
  public tipoOrigen;
  public iscargado = true;
  public ismenu = false;
  ismenudialog=false
  
  constructor(public dialogRef: MatDialogRef<DialogAgregarEncuestaDetalle>, 
     @Inject(MAT_DIALOG_DATA) public data: DialogDataVh,private configuracionesService: ConfiguracionesService, public toastr: ToastrManager,public dialogagregar: MatDialog,public dialogagregarVh: MatDialog,private route: Router) {}
     @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
     displayedColumns: string[] = ['etiqueta','tipoDetalle','fuente'];
     dataSource = new MatTableDataSource();

  ngOnInit(): void {

    this.descargarCodigoTransportador()

 
  }

  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator;

  }

  onNoClick(): void {
    console.log('cerrado');
    this.dialogRef.close();
  
  }
  onFirma(): void {

    this.dialogagregar.open(DialogAgregarEncuestaFecha, {
      width: '950px',
      data: {tipo: "8",
      id: this.codigo,
      tipoOrigen: this.data.tipoOrigen
     
      },
    
    });
    
  
  }
  onNumero(): void {

    this.dialogagregar.open(DialogAgregarEncuestaFecha, {
      width: '950px',
      data: {tipo: "7",
      id: this.codigo,
      tipoOrigen: this.data.tipoOrigen
     
      },
    
    });
    
  
  }

  
  onFecha(): void {

    this.dialogagregar.open(DialogAgregarEncuestaFecha, {
      width: '950px',
      data: {tipo: "1",
      id: this.codigo ,
      tipoOrigen: this.data.tipoOrigen
      },
    
    });
    
  
  }

    
  onFoto(): void {

    this.dialogagregar.open(DialogAgregarEncuestaFecha, {
      width: '950px',
      data: {tipo: "2",
      id: this.codigo,
      tipoOrigen: this.data.tipoOrigen
     
      },
    
    });
    
  
  }

  onLarga(): void {

    this.dialogagregar.open(DialogAgregarEncuestaFecha, {
      width: '950px',
      data: {tipo: "3",
      id: this.codigo,
      tipoOrigen: this.data.tipoOrigen
     
      },
    
    });
    
  
  }

  onCorto(): void {

    this.dialogagregar.open(DialogAgregarEncuestaFecha, {
      width: '950px',
      data: {tipo: "4", id: this.codigo,
      tipoOrigen: this.data.tipoOrigen
    },
    
    });
    
  
  }

  onUnico(): void {

    this.dialogagregar.open(DialogAgregarEncuestaSeleccion, {
      width: '950px',
      data: {tipo: "5",
      id: this.codigo,
      tipoOrigen: this.data.tipoOrigen
     
      },
    
    });
    
  
  }

  onMultiple(): void {

    this.dialogagregar.open(DialogAgregarEncuestaSeleccion, {
      width: '950px',
      data: {tipo: "6",
      id: this.codigo,
      tipoOrigen: this.data.tipoOrigen
      },
    
    });
    
  
  }



  onNuevo(): void {

    this.dialogagregar.open(DialogAgregarEncuesta, {
      width: '950px',
      data: {id: this.codigo,
        tipoOrigen: this.data.tipoOrigen
        
      },
    
    });
    
  
  }

 

  

  crearDetalleEncunesta(data: any) {
   

    if(data.title) {

        
  var conop = {
    con: this.codigo+1

  }

  this.configuracionesService.incrementarcontransportador(conop)



     var tipoDoc = {
        id: this.codigo,
        orden:1,
        title:data.title,
        obligatoria: true,
        agencia:localStorage.getItem("agencia"),
        negocio: localStorage.getItem("negocio"),
        tipoOrigen:this.data.tipoOrigen
    
      }

    
      var hoy = new Date();
      var minuto=  hoy.getMinutes() 
      var segundos=hoy.getSeconds()
  
      var minutefinal="00"
      var segundofinal="00"
      if(minuto<10){
  
        minutefinal="0"+minuto
      }else{
        minutefinal=String(minuto)
      }
      if(segundos<10){
  
        segundofinal="0"+segundos
      }else{
        segundofinal=String(segundos)
      }
      var hora = hoy.getHours() + ':' + minutefinal + ':' + segundofinal;
      var auditria = {
        id: data.id,
        pregunta: data.pregunta,
        tipo: data.tipo,
        agencia:localStorage.getItem("agencia"),
        negocio: localStorage.getItem("negocio"),
        fechahora: formatDate(hoy, 'yyyy-MM-dd HH:mm:ss', 'en-US'),
        fecha: formatDate(hoy, 'yyyy-MM-dd', 'en-US'),
        movimiento:  'Agregar',
    
     
      }
  
     // this.configuracionesService.crearAuditriaTercero(auditria)
      this.configuracionesService.crearEncuenta(tipoDoc)
      this.toastr.successToastr("Pregunta:  creado con éxito", 'Notificación');
      this.dialogRef.close();

    }else{
      this.toastr.errorToastr("Todos los campos de un formulario son obligatorios", 'Notificación');
    } 

 


}

 
  descargarCodigoTransportador() {

    this.configuracionesService.getConTransporte().subscribe(tipoDocus => {

      for (let tipo of tipoDocus) {

           this.codigo=tipo.con
           console.log(' this.codigo', this.codigo);
      
      }  

      this.configuracionesService.getDetalleEncuestas(this.codigo, this.data.tipoOrigen).subscribe(configuraciones => {
  
        this.dataSource.data = configuraciones;
  
        setTimeout(() => {
          this.iscargado = false;
          this.ismenu = true;
         }, 200);
  
      
    
      
      });

    });
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  
}
