
import { ConfiguracionesService } from '../../../../shared/configuraciones.service';
import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { formatDate } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';


export interface DialogDataVh {
  tipo: string;
  id: string;
  pregunta: string;
  tipoOrigen:string;
 
}

@Component({
  selector: 'app-DialogAgregarEncuestaFecha',
  templateUrl: './DialogAgregarEncuestaFecha.component.html',
  styleUrls: ['./DialogAgregarEncuestaFecha.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class DialogAgregarEncuestaFecha implements OnInit {
  tipo: string;
  id: string;
  pregunta: string;
 
  
  public tituloPregunta;
  public tipoDato;
  public tipoDetalle;

  constructor(public dialogRef: MatDialogRef<DialogAgregarEncuestaFecha>, 
     @Inject(MAT_DIALOG_DATA) public data: DialogDataVh,private configuracionesService: ConfiguracionesService, public toastr: ToastrManager) {}


  ngOnInit(): void {

    if (this.data.tipo == "1"){
      this.tituloPregunta = "¡Ingrese la pregunta con respuesta tipo fecha!"
      this.tipoDato = "FE"
      this.tipoDetalle = "Fecha"
    }

    if (this.data.tipo == "2"){
      this.tituloPregunta = "¡Ingrese la pregunta con respuesta tipo foto!"
      this.tipoDato= "IM"
      this.tipoDetalle= "Foto"
    }
    if (this.data.tipo == "3"){
      this.tituloPregunta = "¡Ingrese la pregunta con respuesta tipo texto largo!"
      this.tipoDato= "TL"
      this.tipoDetalle= "Texto largo"
    }

    if (this.data.tipo == "4"){
      this.tituloPregunta = "¡Ingrese la pregunta con respuesta tipo texto corto!"
      this.tipoDato= "TC"
      this.tipoDetalle= "Texto corto"
    }

    if (this.data.tipo == "5"){
      this.tituloPregunta = "¡Ingrese la pregunta con respuesta tipo selección única!"
      this.tipoDato= "SU"
      this.tipoDetalle= "Selección única"
    }

    if (this.data.tipo == "6"){
      this.tituloPregunta = "¡Ingrese la pregunta con respuesta tipo selección múltiple!"
      this.tipoDato= "SM"
      this.tipoDetalle= "Selección Múltiple"
    }

    if (this.data.tipo == "7"){
      this.tituloPregunta = "¡Ingrese la pregunta con respuesta tipo número!"
      this.tipoDato= "NU"
      this.tipoDetalle= "Número"
    }

    if (this.data.tipo == "8"){
      this.tituloPregunta = "¡Ingrese la pregunta con respuesta tipo firma!"
      this.tipoDato= "FM"
      this.tipoDetalle= "Firma"
    }

  }

  onNoClick(): void {
    console.log('cerrado');
    this.dialogRef.close();
  
  }
 
  crearDetalleEncunesta(data: any) {
   

      if(data.pregunta) {

          
    var conop = {
      con: data.id+1

    }

    

    var tipoDoc = {
          id: data.id,
          etiqueta: data.pregunta,
          orden:1,
          metadato: this.tipoDato,
          fuente:"",
          title:this.tipoDetalle,
          obligatoria: true,
          agencia:localStorage.getItem("agencia"),
          negocio: localStorage.getItem("negocio"),
          tipoDetalle:this.tipoDetalle,
          tipoOrigen:this.data.tipoOrigen
        }
  
      
        var hoy = new Date();
        var minuto=  hoy.getMinutes() 
        var segundos=hoy.getSeconds()
    
        var minutefinal="00"
        var segundofinal="00"
        if(minuto<10){
    
          minutefinal="0"+minuto
        }else{
          minutefinal=String(minuto)
        }
        if(segundos<10){
    
          segundofinal="0"+segundos
        }else{
          segundofinal=String(segundos)
        }
        var hora = hoy.getHours() + ':' + minutefinal + ':' + segundofinal;
        var auditria = {
          id: data.id,
          pregunta: data.pregunta,
          tipo: data.tipo,
          agencia:localStorage.getItem("agencia"),
          negocio: localStorage.getItem("negocio"),
          fechahora: formatDate(hoy, 'yyyy-MM-dd HH:mm:ss', 'en-US'),
          fecha: formatDate(hoy, 'yyyy-MM-dd', 'en-US'),
          movimiento:  'Agregar',
      
       
        }
    
       // this.configuracionesService.crearAuditriaTercero(auditria)
        this.configuracionesService.crearDetalleEncuenstas(tipoDoc)
        this.toastr.successToastr("Pregunta:  creado con éxito", 'Notificación');
        this.dialogRef.close();

      }else{
        this.toastr.errorToastr("Todos los campos de un formulario son obligatorios", 'Notificación');
      } 

   


  }

}
